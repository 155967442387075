import React from "react";

function notfount(props) {
  return (
    <section
      className="relative py-24 md:py-44 lg:py-64 bg-white flex items-center"
      style={{
        backgroundImage: "url('flex-ui-assets/elements/pattern-white.svg')",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <div className="relative z-10 container px-4 mx-auto">
        <div className="max-w-4xl mx-auto text-center">
          <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium rounded-full shadow-sm">
            Error 404
          </span>
          <h2 className="mb-4 text-4xl md:text-5xl leading-tight font-bold tracking-tighter">
            Page not found
          </h2>
          <p className="mb-12 text-lg md:text-xl text-coolGray-500">
            Something went wrong, so this page is broken.
          </p>
          <div className="flex flex-wrap justify-center">
            <a href="/">
              <button
                style={{ marginTop: "1em" }}
                className="inline-block py-3 px-7 w-full md:w-auto text-lg leading-7 text-green-50 bg-green-500 hover:bg-green-600 font-medium text-center focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm"
                type="submit"
              >
                Go back to Homepage
              </button>
            </a>
          </div>
        </div>
      </div>
      <img
        className="absolute top-0 left-0 w-28 md:w-auto"
        src="flex-ui-assets/elements/wave2-yellow.svg"
        alt=""
      />
      <img
        className="absolute right-6 top-6 w-28 md:w-auto"
        src="flex-ui-assets/elements/dots3-green.svg"
        alt=""
      />
      <img
        className="absolute right-0 bottom-0 w-28 md:w-auto"
        src="flex-ui-assets/elements/wave3-red.svg"
        alt=""
      />
      <img
        className="absolute left-6 bottom-6 w-28 md:w-auto"
        src="flex-ui-assets/elements/dots3-violet.svg"
        alt=""
      />
    </section>
  );
}

export default notfount;
