import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./index.css";
import FAQ from "./faq";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

const iframeStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  colorScheme: "light",
};

const containerStyle = {
  position: "relative",
  paddingBottom: "calc(49.73958333333333% + 41px)",
  height: 0,
  width: "100%",
};
export default function Index() {
  const vidRef = useRef(null);
  const imgRef = useRef(null);
  const [vidItem, setVidItem] = useState(false);

  useEffect(() => {
    imgRef.current.src =
      "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=406677";
    // Create an intersection observer
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log(entry.isIntersecting);
        if (entry.isIntersecting) {
          entry.target.classList.add("showX");
        } else {
          // entry.target.classList.remove("showX");
        }
      });
    });

    // Select the element to be observed
    const box = document.querySelectorAll(".hiddenX");
    console.log("====================================");
    console.log(box);

    console.log("====================================");
    box.forEach((e) => {
      // Start observing the element
      observer.observe(e);
    });
  }, []);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="overflow-hidden">
          <div
            className="relative bg-white overflow-hidden"
            style={{
              backgroundImage:
                'url("flex-ui-assets/elements/pattern-white.svg")',
              backgroundPosition: "center",
            }}
          >
            <div className="bg-transparent">
              {/**
                  <nav className='flex justify-between p-6 px-4'>
                      <div className='flex justify-between items-center w-full'>
                        <div className='w-1/2 xl:w-1/3'>
                          <a className='block max-w-max' href='#'>
                            <img
                              className='h-8'
                              src='images/Group-213.png'
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-1/2 xl:w-1/3'>
                          <ul className='hidden xl:flex xl:justify-center'>
                            <li className='mr-12'>
                              <a
                                className='text-coolGray-500 hover:text-coolGray-900 font-medium'
                                href='#'
                              >
                                Product
                              </a>
                            </li>
                            <li className='mr-12'>
                              <a
                                className='text-coolGray-500 hover:text-coolGray-900 font-medium'
                                href='#'
                              >
                                Features
                              </a>
                            </li>
                            <li className='mr-12'>
                              <a
                                className='text-coolGray-500 hover:text-coolGray-900 font-medium'
                                href='#'
                              >
                                Resources
                              </a>
                            </li>
                            <li>
                              <a
                                className='text-coolGray-500 hover:text-coolGray-900 font-medium'
                                href='#'
                              >
                                Pricing
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className='w-1/2 xl:w-1/3'>
                          <div className='hidden xl:flex items-center justify-end'>
                            <a
                              className='inline-block py-2 px-4 mr-2 leading-5 text-coolGray-500 hover:text-coolGray-900 bg-transparent font-medium rounded-md'
                              href='#'
                            >
                              Log In
                            </a>
                            <a
                              className='inline-block py-2 px-4 text-sm leading-5 text-green-50 bg-green-500 hover:bg-green-600 font-medium focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md'
                              href='#'
                            >
                              Sign Up
                            </a>
                          </div>
                        </div>
                      </div>
                    <button className='navbar-burger self-center xl:hidden'>
                      <svg
                        width={35}
                        height={35}
                        viewBox='0 0 32 32'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          className='text-coolGray-50'
                          width={32}
                          height={32}
                          rx={6}
                          fill='currentColor'
                        />
                        <path
                          className='text-coolGray-500'
                          d='M7 12H25C25.2652 12 25.5196 11.8946 25.7071 11.7071C25.8946 11.5196 26 11.2652 26 11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10H7C6.73478 10 6.48043 10.1054 6.29289 10.2929C6.10536 10.4804 6 10.7348 6 11C6 11.2652 6.10536 11.5196 6.29289 11.7071C6.48043 11.8946 6.73478 12 7 12ZM25 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H25C25.2652 17 25.5196 16.8946 25.7071 16.7071C25.8946 16.5196 26 16.2652 26 16C26 15.7348 25.8946 15.4804 25.7071 15.2929C25.5196 15.1054 25.2652 15 25 15ZM25 20H7C6.73478 20 6.48043 20.1054 6.29289 20.2929C6.10536 20.4804 6 20.7348 6 21C6 21.2652 6.10536 21.5196 6.29289 21.7071C6.48043 21.8946 6.73478 22 7 22H25C25.2652 22 25.5196 21.8946 25.7071 21.7071C25.8946 21.5196 26 21.2652 26 21C26 20.7348 25.8946 20.4804 25.7071 20.2929C25.5196 20.1054 25.2652 20 25 20Z'
                          fill='currentColor'
                        />
                      </svg>
                    </button>
                  </nav>
                 */}
              <div className="navbar-menu hidden fixed top-0 left-0 z-50 w-full h-full bg-coolGray-900 bg-opacity-50">
                <div className="fixed top-0 left-0 bottom-0 w-full w-4/6 max-w-xs bg-white">
                  <nav className="relative p-6 h-full overflow-y-auto">
                    <div className="flex flex-col justify-between h-full">
                      <a className="inline-block" href="#">
                        <img
                          className="h-8"
                          src="flex-ui-assets/logos/flex-ui-green.svg"
                          alt=""
                        />
                      </a>
                      <ul className="py-6">
                        <li>
                          <a
                            className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md"
                            href="#"
                          >
                            Product
                          </a>
                        </li>
                        <li>
                          <a
                            className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md"
                            href="#"
                          >
                            Features
                          </a>
                        </li>
                        <li>
                          <a
                            className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md"
                            href="#"
                          >
                            Pricing
                          </a>
                        </li>
                        <li>
                          <a
                            className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md"
                            href="#"
                          >
                            Resources
                          </a>
                        </li>
                      </ul>
                      <div className="flex flex-wrap">
                        <div className="w-full mb-2">
                          <a
                            className="inline-block py-2 px-4 w-full text-sm leading-5 text-coolGray-500 hover:text-coolGray-900 bg-transparent font-medium text-center rounded-md"
                            href="#"
                          >
                            Log In
                          </a>
                        </div>
                        <div className="w-full">
                          <a
                            className="inline-block py-2 px-4 w-full text-sm leading-5 text-white bg-green-500 hover:bg-green-600 font-medium text-center focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md"
                            href="#"
                          >
                            Sign Up
                          </a>
                        </div>
                      </div>
                    </div>
                  </nav>
                  <a
                    className="navbar-close absolute top-5 p-4 right-3"
                    href="#"
                  >
                    <svg
                      width={12}
                      height={12}
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.94004 6L11.14 1.80667C11.2656 1.68113 11.3361 1.51087 11.3361 1.33333C11.3361 1.1558 11.2656 0.985537 11.14 0.860002C11.0145 0.734466 10.8442 0.66394 10.6667 0.66394C10.4892 0.66394 10.3189 0.734466 10.1934 0.860002L6.00004 5.06L1.80671 0.860002C1.68117 0.734466 1.51091 0.663941 1.33337 0.663941C1.15584 0.663941 0.985576 0.734466 0.860041 0.860002C0.734505 0.985537 0.66398 1.1558 0.66398 1.33333C0.66398 1.51087 0.734505 1.68113 0.860041 1.80667L5.06004 6L0.860041 10.1933C0.797555 10.2553 0.747959 10.329 0.714113 10.4103C0.680267 10.4915 0.662842 10.5787 0.662842 10.6667C0.662842 10.7547 0.680267 10.8418 0.714113 10.9231C0.747959 11.0043 0.797555 11.078 0.860041 11.14C0.922016 11.2025 0.99575 11.2521 1.07699 11.2859C1.15823 11.3198 1.24537 11.3372 1.33337 11.3372C1.42138 11.3372 1.50852 11.3198 1.58976 11.2859C1.671 11.2521 1.74473 11.2025 1.80671 11.14L6.00004 6.94L10.1934 11.14C10.2554 11.2025 10.3291 11.2521 10.4103 11.2859C10.4916 11.3198 10.5787 11.3372 10.6667 11.3372C10.7547 11.3372 10.8419 11.3198 10.9231 11.2859C11.0043 11.2521 11.0781 11.2025 11.14 11.14C11.2025 11.078 11.2521 11.0043 11.286 10.9231C11.3198 10.8418 11.3372 10.7547 11.3372 10.6667C11.3372 10.5787 11.3198 10.4915 11.286 10.4103C11.2521 10.329 11.2025 10.2553 11.14 10.1933L6.94004 6Z"
                        fill="#556987"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="pt-12 pb-28 md:pb-72">
              <div className="container px-4 mx-auto">
                <div className="mx-auto text-center max-w-3xl">
                  <span className="inline-block py-px px-2 mb-6 text-xs leading-5 text-white uppercase rounded-9xl hiddenX">
                    <img src="/new-icon.png" style={{ height: "2rem" }} />
                  </span>
                  <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tighter hiddenX">
                    Welcome to the Future of Information Accessibility
                  </h1>
                  <p className="mb-8 mx-auto text-lg md:text-xl text-coolGray-500 font-medium max-w-3xl hiddenX">
                    Experience the power of AI with{" "}
                    <span className="text-green-500">
                      <i>asqme.ai</i>
                    </span>
                    . Transform your PDFs into smart chatbots. Be an early bird,
                    join the waitlist and enjoy a free month!
                  </p>
                  <div className="flex flex-wrap justify-center hiddenX flex-col items-center">
                    {/* 
                    <form
                      action="https://submit-form.com/csbpgPwN"
                      className="flex flex-col override-w-size"
                    >
                      <input
                        type="hidden"
                        name="_redirect"
                        value="https://asqme.ai/thankyou"
                      />
                      <div className="override-flex-row">
                        <input
                          className="w-full py-3 px-4 text-coolGray-500 leading-tight placeholder-coolGray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-coolGray-200 rounded-lg shadow-xs"
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Your email for early access"
                          required=""
                        />
                        <button
                          class="inline-block py-3 px-7 w-full md:w-auto text-lg leading-7 text-green-50 bg-green-500 hover:bg-green-600 font-medium text-center focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm"
                          type="submit"
                        >
                          Join Now!
                        </button>
                      </div>

                      <label
                        className="text-coolGray-400 text-left"
                        style={{ width: "100%", fontSize: "14px" }}
                      >
                        <i>
                          Join the{" "}
                          <span className="text-green-500">
                            <i>asqme.ai</i>
                          </span>{" "}
                          Waitlist
                        </i>
                      </label>
                    </form>
                    */}
                    <div className="flex">
                      <div className="w-full md:w-auto py-1 md:py-0 md:mr-4">
                        <a
                          className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-coolGray-800 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-200 focus:ring-opacity-50 border border-coolGray-200 rounded-md shadow-sm"
                          href="#demo"
                        >
                          See a Demo
                        </a>
                      </div>
                      <div className="w-full md:w-auto py-1 md:py-0">
                        <a
                          className="inline-block py-5 px-7 w-full text-base md:text-lg leading-4 text-green-50 font-medium text-center bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-green-500 rounded-md shadow-sm"
                          href="https://studio.asqme.ai"
                          target="_blank"
                          onclick="return gtag_report_conversion('https://studio.asqme.ai');"
                        >
                          Get started
                        </a>
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "2em",
                      }}
                    >
                      <a
                        href="https://www.producthunt.com/posts/asqme-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-asqme&#0045;ai"
                        target="_blank"
                      >
                        <img
                          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=406677"
                          alt="Asqme AI | Product Hunt"
                          ref={imgRef}
                          style={{ width: "250px", height: "54px" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container px-4 py-20 md:pb-32 mx-auto -mt-32 md:-mt-72  hiddenX">
            <div className="relative mx-auto max-w-max">
              <div className="relative overflow-hidden rounded-7xl">
                {!vidItem && (
                  <svg
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 cursor-pointer text-green-500 hover:text-green-600"
                    width={64}
                    height={64}
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={(e) => {
                      vidRef.current.play();
                      setVidItem(true);
                    }}
                  >
                    <circle cx={32} cy={32} r={32} fill="currentColor" />
                    <path
                      className="text-white"
                      d="M40.5 31.13L26.5 23.05C26.348 22.9622 26.1755 22.916 26 22.916C25.8245 22.916 25.652 22.9622 25.5 23.05C25.3474 23.1381 25.2208 23.265 25.133 23.4177C25.0452 23.5705 24.9993 23.7438 25 23.92V40.08C24.9993 40.2562 25.0452 40.4295 25.133 40.5822C25.2208 40.735 25.3474 40.8619 25.5 40.95C25.652 41.0378 25.8245 41.084 26 41.084C26.1755 41.084 26.348 41.0378 26.5 40.95L40.5 32.87C40.6539 32.7828 40.7819 32.6563 40.871 32.5035C40.96 32.3506 41.007 32.1769 41.007 32C41.007 31.8231 40.96 31.6494 40.871 31.4965C40.7819 31.3437 40.6539 31.2172 40.5 31.13ZM27 38.35V25.65L38 32L27 38.35Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
                <video
                  controls={false}
                  className="mx-auto text-center"
                  ref={vidRef}
                  poster="/cover-image.png"
                >
                  <source src="/intro-video.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </section>
        <section
          className="py-24 bg-white"
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="container px-4 mx-auto">
            <div className="max-w-5xl mx-auto text-center mb-16 md:mb-24">
              <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-full shadow-sm hiddenX">
                How it works
              </span>
              <h2 className="mb-4 text-4xl md:text-5xl leading-tight font-bold tracking-tighter hiddenX">
                Gain more insight into how people use your
              </h2>
              <p className="text-lg md:text-xl text-coolGray-500 font-medium hiddenX">
                With our integrated CRM, project management, collaboration and
                invoicing capabilities, you can manage every aspect of your
                business in one secure platform.
              </p>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16 hiddenX">
                <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
                  <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                    <div className="inline-flex items-center justify-center w-12 h-12 bg-green-500 rounded-full text-xl font-semibold text-white">
                      1
                    </div>
                  </div>
                  <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">
                    Submit Your PDFs
                  </h3>
                  <p className="text-coolGray-500 font-medium">
                    Simply provide us with your textual data in PDF format.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16 hiddenX transition-delay-x1">
                <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
                  <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                    <div className="inline-flex items-center justify-center w-12 h-12 bg-green-500 rounded-full text-xl font-semibold text-white">
                      2
                    </div>
                  </div>
                  <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">
                    Activate{" "}
                    <span className="text-green-500">
                      <i>asqme.ai</i>
                    </span>
                    's AI
                  </h3>
                  <p className="text-coolGray-500 font-medium">
                    Watch as our AI analyzes your data and creates a dynamic,
                    smart chatbot.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16 hiddenX transition-delay-x2">
                <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
                  <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                    <div className="inline-flex items-center justify-center w-12 h-12 bg-green-500 rounded-full text-xl font-semibold text-white">
                      3
                    </div>
                  </div>
                  <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">
                    Engage with Your Chatbot
                  </h3>
                  <p className="text-coolGray-500 font-medium">
                    Start a conversation. Your chatbot is ready to answer any
                    questions based on the data provided.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16 lg:mb-0"></div>
              <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16 md:mb-0"></div>
              <div className="w-full md:w-1/2 lg:w-1/3 px-4"></div>
            </div>
            <div style={containerStyle} className="hiddenX">
              <iframe
                src="https://demo.arcade.software/h0WvaO7ah64IKCjPBo8q?embed"
                loading="lazy"
                id="demo"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                style={iframeStyle}
                title="Asqme Studio"
              ></iframe>
            </div>
          </div>
        </section>
        <section
          className="py-24 bg-white"
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="container px-4 mx-auto">
            <div className="xl:max-w-4xl mb-12 mx-auto text-center">
              <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-full shadow-sm hiddenX">
                Features
              </span>
              <h1 className="mb-4 text-3xl md:text-4xl leading-tight font-bold tracking-tighter hiddenX">
                Redefining Interactions with{" "}
                <span className="text-green-500">
                  <i>asqme.ai</i>
                </span>
              </h1>
              <p className="text-lg md:text-xl text-coolGray-500 font-medium hiddenX">
                With our integrated CRM, project management, collaboration and
                invoicing capabilities, you can manage every aspect of your
                business in one secure platform.
              </p>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/3 px-4 lg:pt-6 mb-8 lg:mb-0">
                <div className="hiddenX p-8 lg:mb-6 text-center hover:bg-white rounded-md hover:shadow-xl transition duration-200">
                  <div className="inline-flex h-16 w-16 mb-6 mx-auto items-center justify-center text-white bg-green-500 rounded-lg">
                    <svg
                      width={24}
                      height={21}
                      viewBox="0 0 24 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 18.63H5C4.20435 18.63 3.44129 18.3139 2.87868 17.7513C2.31607 17.1887 2 16.4257 2 15.63V7.63C2 7.36479 1.89464 7.11043 1.70711 6.9229C1.51957 6.73536 1.26522 6.63 1 6.63C0.734784 6.63 0.48043 6.73536 0.292893 6.9229C0.105357 7.11043 0 7.36479 0 7.63L0 15.63C0 16.9561 0.526784 18.2279 1.46447 19.1655C2.40215 20.1032 3.67392 20.63 5 20.63H17C17.2652 20.63 17.5196 20.5246 17.7071 20.3371C17.8946 20.1496 18 19.8952 18 19.63C18 19.3648 17.8946 19.1104 17.7071 18.9229C17.5196 18.7354 17.2652 18.63 17 18.63ZM21 0.630005H7C6.20435 0.630005 5.44129 0.946075 4.87868 1.50868C4.31607 2.07129 4 2.83436 4 3.63V13.63C4 14.4257 4.31607 15.1887 4.87868 15.7513C5.44129 16.3139 6.20435 16.63 7 16.63H21C21.7956 16.63 22.5587 16.3139 23.1213 15.7513C23.6839 15.1887 24 14.4257 24 13.63V3.63C24 2.83436 23.6839 2.07129 23.1213 1.50868C22.5587 0.946075 21.7956 0.630005 21 0.630005ZM20.59 2.63L14.71 8.51C14.617 8.60373 14.5064 8.67813 14.3846 8.7289C14.2627 8.77966 14.132 8.8058 14 8.8058C13.868 8.8058 13.7373 8.77966 13.6154 8.7289C13.4936 8.67813 13.383 8.60373 13.29 8.51L7.41 2.63H20.59ZM22 13.63C22 13.8952 21.8946 14.1496 21.7071 14.3371C21.5196 14.5246 21.2652 14.63 21 14.63H7C6.73478 14.63 6.48043 14.5246 6.29289 14.3371C6.10536 14.1496 6 13.8952 6 13.63V4L11.88 9.88C12.4425 10.4418 13.205 10.7574 14 10.7574C14.795 10.7574 15.5575 10.4418 16.12 9.88L22 4V13.63Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <h3 className="mb-4 text-xl md:text-2xl leading-tight font-bold">
                    Intelligent Learning Chatbot
                  </h3>
                  <p className="text-coolGray-500 font-medium">
                    Harness a chatbot that evolves with each conversation,
                    constantly enhancing its ability to respond.
                  </p>
                </div>
                <div className="hiddenX p-8 text-center hover:bg-white rounded-md hover:shadow-xl transition duration-200 transition-delay-x1">
                  <div className="inline-flex h-16 w-16 mb-6 mx-auto items-center justify-center text-white bg-green-500 rounded-lg">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 18H9.24C9.37161 18.0008 9.50207 17.9755 9.62391 17.9258C9.74574 17.876 9.85656 17.8027 9.95 17.71L16.87 10.78L19.71 8C19.8037 7.90704 19.8781 7.79644 19.9289 7.67458C19.9797 7.55272 20.0058 7.42201 20.0058 7.29C20.0058 7.15799 19.9797 7.02728 19.9289 6.90542C19.8781 6.78356 19.8037 6.67296 19.71 6.58L15.47 2.29C15.377 2.19627 15.2664 2.12188 15.1446 2.07111C15.0227 2.02034 14.892 1.9942 14.76 1.9942C14.628 1.9942 14.4973 2.02034 14.3754 2.07111C14.2536 2.12188 14.143 2.19627 14.05 2.29L11.23 5.12L4.29 12.05C4.19732 12.1434 4.12399 12.2543 4.07423 12.3761C4.02446 12.4979 3.99924 12.6284 4 12.76V17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18ZM14.76 4.41L17.59 7.24L16.17 8.66L13.34 5.83L14.76 4.41ZM6 13.17L11.93 7.24L14.76 10.07L8.83 16H6V13.17ZM21 20H3C2.73478 20 2.48043 20.1054 2.29289 20.2929C2.10536 20.4804 2 20.7348 2 21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21C22 20.7348 21.8946 20.4804 21.7071 20.2929C21.5196 20.1054 21.2652 20 21 20Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <h3 className="mb-4 text-xl md:text-2xl leading-tight font-bold">
                    Real-Time Audio Responses
                  </h3>
                  <p className="text-coolGray-500 font-medium">
                    Experience dynamic audios as the chatbot shares real-time
                    responses, adding another layer to your interaction.
                  </p>
                </div>
              </div>
              <div className="hiddenX w-full lg:w-1/3 px-4 mb-8 lg:mb-0 transition-delay-x2">
                <img className="mx-auto" src="/images/app-show.svg" alt="" />
              </div>
              <div className="hiddenX w-full lg:w-1/3 lg:pt-6 px-4 transition-delay-x3">
                <div className="p-8 lg:mb-6 text-center hover:bg-white rounded-md hover:shadow-xl transition duration-200">
                  <div className="inline-flex h-16 w-16 mb-6 mx-auto items-center justify-center text-white bg-green-500 rounded-lg">
                    <svg
                      width={22}
                      height={12}
                      viewBox="0 0 22 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.71 1.29C20.617 1.19627 20.5064 1.12188 20.3846 1.07111C20.2627 1.02034 20.132 0.994202 20 0.994202C19.868 0.994202 19.7373 1.02034 19.6154 1.07111C19.4936 1.12188 19.383 1.19627 19.29 1.29L13 7.59L8.71 3.29C8.61704 3.19627 8.50644 3.12188 8.38458 3.07111C8.26272 3.02034 8.13201 2.9942 8 2.9942C7.86799 2.9942 7.73728 3.02034 7.61542 3.07111C7.49356 3.12188 7.38296 3.19627 7.29 3.29L1.29 9.29C1.19627 9.38296 1.12188 9.49356 1.07111 9.61542C1.02034 9.73728 0.994202 9.86799 0.994202 10C0.994202 10.132 1.02034 10.2627 1.07111 10.3846C1.12188 10.5064 1.19627 10.617 1.29 10.71C1.38296 10.8037 1.49356 10.8781 1.61542 10.9289C1.73728 10.9797 1.86799 11.0058 2 11.0058C2.13201 11.0058 2.26272 10.9797 2.38458 10.9289C2.50644 10.8781 2.61704 10.8037 2.71 10.71L8 5.41L12.29 9.71C12.383 9.80373 12.4936 9.87812 12.6154 9.92889C12.7373 9.97966 12.868 10.0058 13 10.0058C13.132 10.0058 13.2627 9.97966 13.3846 9.92889C13.5064 9.87812 13.617 9.80373 13.71 9.71L20.71 2.71C20.8037 2.61704 20.8781 2.50644 20.9289 2.38458C20.9797 2.26272 21.0058 2.13201 21.0058 2C21.0058 1.86799 20.9797 1.73728 20.9289 1.61542C20.8781 1.49356 20.8037 1.38296 20.71 1.29Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <h3 className="mb-4 text-xl md:text-2xl leading-tight font-bold">
                    Visual &amp; Auditory Interaction
                  </h3>
                  <p className="text-coolGray-500 font-medium">
                    With Smart Face &amp; Lip-Syncing features, interact with a
                    chatbot that goes beyond text.
                  </p>
                </div>
                <div className="p-8 text-center hover:bg-white rounded-md hover:shadow-xl transition duration-200">
                  <div className="inline-flex h-16 w-16 mb-6 mx-auto items-center justify-center text-white bg-green-500 rounded-lg">
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 13H3C2.73478 13 2.48043 13.1054 2.29289 13.2929C2.10536 13.4804 2 13.7348 2 14V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H10C10.2652 22 10.5196 21.8946 10.7071 21.7071C10.8946 21.5196 11 21.2652 11 21V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 20H4V15H9V20ZM21 2H14C13.7348 2 13.4804 2.10536 13.2929 2.29289C13.1054 2.48043 13 2.73478 13 3V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H21C21.2652 11 21.5196 10.8946 21.7071 10.7071C21.8946 10.5196 22 10.2652 22 10V3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2V2ZM20 9H15V4H20V9ZM21 13H14C13.7348 13 13.4804 13.1054 13.2929 13.2929C13.1054 13.4804 13 13.7348 13 14V21C13 21.2652 13.1054 21.5196 13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22H21C21.2652 22 21.5196 21.8946 21.7071 21.7071C21.8946 21.5196 22 21.2652 22 21V14C22 13.7348 21.8946 13.4804 21.7071 13.2929C21.5196 13.1054 21.2652 13 21 13ZM20 20H15V15H20V20ZM10 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V10C2 10.2652 2.10536 10.5196 2.29289 10.7071C2.48043 10.8946 2.73478 11 3 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2V2ZM9 9H4V4H9V9Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <h3 className="mb-4 text-xl md:text-2xl leading-tight font-bold">
                    &nbsp;Control &amp; Transparency with Q&amp;As
                  </h3>
                  <p className="text-coolGray-500 font-medium">
                    Our White Box feature allows you to manage Q&amp;As via UI,
                    ensuring you always hold the reins.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="py-24 bg-white"
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full md:w-1/2 px-4 mb-20 md:mb-0">
                <div className="max-w-md">
                  <span className="hiddenX inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium rounded-full shadow-sm">
                    FAQ
                  </span>
                  <h2 className="hiddenX mb-4 text-4xl md:text-5xl leading-tight text-coolGray-900 font-bold tracking-tighter">
                    Frequently Asked Questions
                  </h2>
                </div>
              </div>
              <FAQ />
            </div>
          </div>
        </section>
        <section
          className="py-24 md:py-32"
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center -mx-4">
              <div className="w-full md:w-1/2 px-4 mb-14 md:mb-0">
                <div className="max-w-md">
                  <h2 className="mb-4 text-3xl md:text-4xl font-heading font-bold hiddenX">
                    Unlock Your Data Potential
                  </h2>
                  <p className="text-lg md:text-xl font-heading font-medium text-coolGray-500 hiddenX">
                    Unearth{" "}
                    <span className="text-green-500">
                      <i>asqme.ai</i>
                    </span>
                    's possibilities. Begin your data-driven journey here!
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="flex flex-wrap items-center md:justify-end">
                  {/*
                    <a
                      className="inline-flex items-center justify-center px-7 py-3 h-14 w-full md:w-auto mb-2 md:mb-0 md:mr-4 text-lg leading-7 text-green-50 bg-green-500 hover:bg-green-600 font-medium focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm"
                      href="#"
                    >
                      Get Started
                    </a>
                    <a
                      className="inline-flex items-center justify-center px-7 py-3 h-14 w-full md:w-auto text-lg leading-7 text-coolGray-800 bg-white hover:bg-coolGray-100 font-medium focus:ring-2 focus:ring-coolGray-200 focus:ring-opacity-50 border border-coolGray-200 border border-coolGray-100 rounded-md shadow-sm"
                      href="#"
                    >
                      Learn More
                    </a>
                  */}
                  <div className="w-full md:w-auto py-1 md:py-0">
                    <a
                      className="inline-block py-3 px-7 w-full md:w-auto text-lg leading-7 text-green-50 bg-green-500 hover:bg-green-600 font-medium text-center focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm"
                      href="https://studio.asqme.ai"
                      onclick="return gtag_report_conversion('https://studio.asqme.ai');"
                      target="_blank"
                    >
                      Get started for free
                    </a>
                  </div>

                  {/*
                   <form
                    action="https://submit-form.com/csbpgPwN"
                    className="flex flex-col override-w-size hiddenX"
                  >
                    <input
                      type="hidden"
                      name="_redirect"
                      value="https://asqme.ai/thankyou"
                    />
                    <div className="override-flex-row">
                      <input
                        className="w-full py-3 px-4 text-coolGray-500 leading-tight placeholder-coolGray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-coolGray-200 rounded-lg shadow-xs"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Your email for early access"
                        required=""
                      />
                      <button
                        class="inline-block py-3 px-7 w-full md:w-auto text-lg leading-7 text-green-50 bg-green-500 hover:bg-green-600 font-medium text-center focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm"
                        type="submit"
                      >
                        Join Now!
                      </button>
                    </div>
                    <label
                      className="text-coolGray-400 text-left"
                      style={{ width: "100%", fontSize: "14px" }}
                    >
                      <i>
                        Join the{" "}
                        <span className="text-green-500">
                          <i>asqme.ai</i>
                        </span>{" "}
                        Waitlist
                      </i>
                    </label>
                  </form>
                  */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="bg-white overflow-hidden"
          style={{
            backgroundImage: 'url("flex-ui-assets/elements/pattern-white.svg")',
            backgroundPosition: "center",
          }}
        >
          <div className="border-b border-coolGray-100" />
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center py-12 -mx-4">
              <div className="w-full md:w-1/2 px-4 override-flex">
                <a className="block mr-2" href="/">
                  <img className="h-8" src="images/Group-213.png" alt="" />
                </a>
                <p className="mb-3 md:mb-0 text-coolGray-400 font-medium">
                  © 2023 Asqme. All rights reserved.
                </p>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="flex flex-wrap justify-end -mx-3 md:-mx-4">
                  <div className="w-full md:w-auto p-3 md:py-0 md:px-4">
                    <a
                      className="inline-block text-coolGray-400 hover:text-coolGray-500 font-medium"
                      href="/impressum"
                    >
                      Impressum
                    </a>
                  </div>
                  <div className="w-full md:w-auto p-3 md:py-0 md:px-4">
                    <a
                      className="inline-block text-coolGray-400 hover:text-coolGray-500 font-medium"
                      href="/terms"
                    >
                      Terms
                    </a>
                  </div>
                  <div className="w-full md:w-auto p-3 md:py-0 md:px-4">
                    <a
                      className="inline-block text-coolGray-400 hover:text-coolGray-500 font-medium"
                      href="/policy"
                    >
                      Privacy
                    </a>
                  </div>
                  <div className="w-full md:w-auto p-3 md:py-0 md:px-4">
                    <a
                      className="inline-block text-coolGray-400 hover:text-coolGray-500 font-medium"
                      href="/cookies"
                    >
                      Cookies
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
