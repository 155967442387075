import React from "react";

function thankyou(props) {
  return (
    <div>
      <section
        className="py-24 md:pb-28 bg-white"
        style={{
          backgroundImage: "url('flex-ui-assets/elements/pattern-white.svg')",
          backgroundPosition: "center",
        }}
      >
        <div className="container px-4 mx-auto">
          <div className="relative max-w-5xl sm:px-6 pb-6 mb-10 mx-auto text-center">
            <img
              className="absolute top-0 left-0"
              src="flex-ui-assets/elements/testimonials/quote-top-green.svg"
              alt=""
            />
            <img
              className="absolute bottom-0 right-0"
              src="flex-ui-assets/elements/testimonials/quote-down-green.svg"
              alt=""
            />
            <div className="relative">
              <span
                className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-9xl"
                contenteditable="false"
              >
                congratulation!
              </span>
              <h2
                className="text-2xl md:text-5xl font-semibold tracking-tighter"
                contenteditable="false"
              >
                Thank you for joining the asqme.ai waitlist! We'll be in touch with
                more exciting information shortly. Stay tuned!
              </h2>
              <a href="/">
                <button
                  style={{ marginTop: "2em" }}
                  className="inline-block py-3 px-7 w-full md:w-auto text-lg leading-7 text-green-50 bg-green-500 hover:bg-green-600 font-medium text-center focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm"
                  type="submit"
                >
                  Go back to Homepage
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default thankyou;
