import React, { useState } from "react";
import "../anime.css";

const data = [
  {
    question:
      'What is <i><span class="text-green-500">asqme.ai</span></i>?',
    answer:
      '<i><span class="text-green-500">asqme.ai</span></i> is an AI-powered tool that transforms your static text data (PDFs) into a dynamic, interactive chatbot capable of answering any questions based on the input data.',
  },
  {
    question:
      'How does <i><span class="text-green-500">asqme.ai</span></i> work?',
    answer:
      'Simply upload your textual data in PDF format, and <i><span class="text-green-500">asqme.ai</span></i> will use AI to process the data and create a smart, responsive chatbot. You can then ask the chatbot any questions related to the input data.',
  },
  {
    question:
      'What makes <i><span class="text-green-500">asqme.ai</span></i> different from other chatbots?',
    answer:
      '<i><span class="text-green-500">asqme.ai</span></i> stands out with features like Smart Face & Lip-Syncing, Dynamic Audios, and White Box Q&As. It offers a learning chatbot that gets smarter with each interaction and gives you the transparency to manage your Q&As.',
  },
  {
    question:
      'Is <i><span class="text-green-500">asqme.ai</span></i> suitable for my business?',
    answer:
      '<i><span class="text-green-500">asqme.ai</span></i> is designed for a wide range of users - from businesses dealing with repetitive customer queries to individuals looking for a more interactive way to engage with textual data. It is an innovative tool for anyone aiming to revolutionize the way they access information.',
  },
  {
    question:
      'How can I get started with <i><span class="text-green-500">asqme.ai</span></i>?',
    answer:
      'To start with <i><span class="text-green-500">asqme.ai</span></i>, join our waitlist and be among the first to experience this transformative tool. Early subscribers get the added advantage of enjoying one month free!',
  },
];

function faq(props) {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleClick = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  return (
    <div class="w-full md:w-1/2 px-4">
      {data.map((item, index) => (
        <div
          key={index + "-faq"}
          class="flex flex-wrap w-full mb-10 text-left cursor-pointer"
          onClick={() => handleClick(index)}
        >
          <div class="w-full md:w-auto md:pr-10 mb-2 md:mb-0">
            <h3
              class="mb-4 text-xl text-coolGray-900 font-bold"
              dangerouslySetInnerHTML={{ __html: item.question }}
            >
            </h3>
            <p
              class={
                "max-w-md text-coolGray-500 font-medium overflow-hidden faq-anim " +
                (expandedIndex === index ? "expand" : "")
              }
              dangerouslySetInnerHTML={{ __html: item.answer }}
            ></p>
          </div>
          <div class="ml-auto text-green-500">
            <div
              style={{
                transition: "all 1s",
                transform:
                  index == expandedIndex ? "rotate(180deg)" : "rotate(0)",
              }}
            >
              <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.29 15.71C11.3851 15.801 11.4972 15.8724 11.62 15.92C11.8635 16.02 12.1365 16.02 12.38 15.92C12.5028 15.8724 12.6149 15.801 12.71 15.71L15.71 12.71C15.8983 12.5217 16.0041 12.2663 16.0041 12C16.0041 11.7337 15.8983 11.4783 15.71 11.29C15.5217 11.1017 15.2663 10.9959 15 10.9959C14.7337 10.9959 14.4783 11.1017 14.29 11.29L13 12.59L13 9C13 8.73479 12.8946 8.48043 12.7071 8.2929C12.5196 8.10536 12.2652 8 12 8C11.7348 8 11.4804 8.10536 11.2929 8.2929C11.1054 8.48043 11 8.73479 11 9L11 12.59L9.71 11.29C9.61704 11.1963 9.50644 11.1219 9.38458 11.0711C9.26272 11.0203 9.13201 10.9942 9 10.9942C8.86799 10.9942 8.73728 11.0203 8.61542 11.0711C8.49356 11.1219 8.38296 11.1963 8.29 11.29C8.19627 11.383 8.12188 11.4936 8.07111 11.6154C8.02034 11.7373 7.9942 11.868 7.9942 12C7.9942 12.132 8.02034 12.2627 8.07111 12.3846C8.12188 12.5064 8.19627 12.617 8.29 12.71L11.29 15.71ZM12 22C13.9778 22 15.9112 21.4135 17.5557 20.3147C19.2002 19.2159 20.4819 17.6541 21.2388 15.8268C21.9957 13.9996 22.1937 11.9889 21.8079 10.0491C21.422 8.10929 20.4696 6.32746 19.0711 4.92894C17.6725 3.53041 15.8907 2.578 13.9509 2.19215C12.0111 1.8063 10.0004 2.00433 8.17317 2.76121C6.3459 3.51809 4.78412 4.79981 3.6853 6.4443C2.58649 8.08879 2 10.0222 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22ZM12 4C13.5823 4 15.129 4.4692 16.4446 5.34825C17.7602 6.2273 18.7855 7.47673 19.391 8.93854C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84286 9.87827 4 12 4Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default faq;
