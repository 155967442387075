import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import NotFound from './pages/404.js';
import ThankYou from './pages/thankyou.js';

import Cookies from './pages/cookies.js';
import Terms from './pages/terms.js';
import Policy from './pages/policy.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />{
      /*
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
      */}
      <Route path="/thankyou" element={<ThankYou />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
}

export default App;
